<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/70mm-min.png"
          width="307"
          height="169"
          alt="70mm Impingement Gun"
          title="70mm Impingement Gun"
          class="img-responsive m-auto"
        />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Impingement Guns" size="large" />
        <Paragraph
          ><p>
            Energetic materials are damaged when subjected to extreme strain
            (case burst, nozzle loss) or high-velocity impact against a hard
            object. Damaged energetics are more susceptible to initiation and
            may transition to a more severe reaction, including detonation.
          </p>
          <p>
            Safety Management Services, Inc. (SMS) manufactures a series of
            impingement guns used to study the effects of high velocity impact
            of propellants and explosives against various target materials. The
            impingement guns can be made for any bore size, including the
            following standard sizes:
          </p>
          <ul>
            <li>17mm (simulation of 12 gauge shotgun)</li>
            <li>18mm (UN / STANAG required test for propellants)</li>
            <li>25mm (conventional DoD test standard)</li>
            <li>70mm (conventional DoD test standard)</li>
          </ul>
          <p>
            Barrel length can be selected for optimum performance or may be
            adapted for limited space requirements (interchangeable barrels on
            some models).
          </p>
          <p>
            SMS’s impingement guns are designed to be fired remotely and are
            equipped with a safety interlock on the firing mechanism. The guns
            may be used in UN Test 7(c)(i) “Susan Impact test” and UN Test
            7(c)(ii) / 7(d)(ii) “Friability test” prescribed in the UN Manual of
            Tests and Criteria and TB 700-2.
          </p>
          <p></p>
          <div
            id="attachment_12239"
            style="width: 317px"
            class="wp-caption alignleft"
          >
            <a href="@/assets/TestEquipment/18mm-min.png"
              ><img
                aria-describedby="caption-attachment-12239"
                class="size-full wp-image-12239"
                src="@/assets/TestEquipment/18mm-min.png"
                alt="18mm Impingement Gun"
                width="307"
                height="241"
            /></a>
            <p id="caption-attachment-12239" class="wp-caption-text">
              18mm Impingement Gun
            </p>
          </div>
          <div
            id="attachment_12240"
            style="width: 317px"
            class="wp-caption alignleft"
          >
            <img
              aria-describedby="caption-attachment-12240"
              class="size-full wp-image-12240"
              src="@/assets/TestEquipment/25mm-min.png"
              alt="25mm Impingement Gun"
              width="307"
              height="159"
            />
            <p id="caption-attachment-12240" class="wp-caption-text">
              25mm Impingement Gun
            </p>
          </div>
          <div
            id="attachment_12237"
            style="width: 317px"
            class="wp-caption alignleft"
          >
            <img
              aria-describedby="caption-attachment-12237"
              class="size-full wp-image-12237"
              src="@/assets/TestEquipment/70mm-min.png"
              alt="70mm Impingement Gun"
              width="307"
              height="169"
            />
            <p id="caption-attachment-12237" class="wp-caption-text">
              70mm Impingement Gun
            </p>
          </div>
          <p></p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Impingement Guns",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS manufactures a series of impingement guns used to study the effects of high velocity impact of propellants and explosives against various target materials. The apparatus can be manufactured to meet various UN and DoD test standards."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
